import { store } from '@/store/store';
import { entrevista } from '@/shared/dtos/entrevista';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { UtilsFiles } from '@/utils/utils-files';

@Module({
    namespaced: true,
    name: 'entrevistaModule',
    store,
    dynamic: true
})
class entrevistaModule extends VuexModule {
    public entrevistas: entrevista[] = [];
    public entrevista: entrevista = new entrevista();

    @Action({ commit: 'onGetentrevistas' })
    public async getentrevistas(id: number) {
        return await ssmHttpService.get(API.entrevista + '/paciente/' + id);
    }

    @Action({ commit: 'onGetentrevista' })
    public async getultimaentrevista(id: number) {
        return await ssmHttpService.get(API.entrevista + '/ultima_paciente/' + id);
    }

    @Action({ commit: 'onGetentrevista' })
    public async getnuevaentrevista(id: number) {
        return await ssmHttpService.get(API.entrevista + '/nueva_paciente/' + id);
    }


    @Action({ commit: 'onGetentrevista' })
    public async getentrevista({ id_paciente, id_entrevista }: any) {
        return await ssmHttpService.get(API.entrevista + "?id_paciente=" + id_paciente + "&id_entrevista=" + id_entrevista);
    }

    @Action({ commit: 'onGuardarEntrevista' })
    public async guardarentrevista(obj_entrevista: entrevista) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        let mi_entrevista: entrevista = new entrevista();
        mi_entrevista.id = obj_entrevista.id;
        mi_entrevista.actividad_fisica = obj_entrevista.actividad_fisica;
        mi_entrevista.alimentacion = obj_entrevista.alimentacion;
        mi_entrevista.altura = obj_entrevista.altura;
        mi_entrevista.cadera = obj_entrevista.cadera;
        mi_entrevista.calorias_dieta = obj_entrevista.calorias_dieta;
        mi_entrevista.cintura = obj_entrevista.cintura;
        mi_entrevista.fecha_entrevista = obj_entrevista.fecha_entrevista;
        mi_entrevista.fecha_nacimiento = obj_entrevista.fecha_nacimiento;
        mi_entrevista.grasas_dieta = obj_entrevista.grasas_dieta;
        mi_entrevista.id_objetivo = obj_entrevista.id_objetivo;
        mi_entrevista.muñeca = obj_entrevista.muñeca;
        mi_entrevista.numero_paciente = obj_entrevista.numero_paciente;
        mi_entrevista.objetivo = obj_entrevista.objetivo;
        mi_entrevista.peso = obj_entrevista.peso;
        //debe de ser un numero entero con lo que un decimal no nos vale, acercamos a el decimal más proximo
        mi_entrevista.porcen_grasa = Math.ceil(obj_entrevista.porcen_grasa);
        mi_entrevista.practica_deporte = obj_entrevista.practica_deporte;
        mi_entrevista.proteinas_dieta = obj_entrevista.proteinas_dieta;
        mi_entrevista.sexo = obj_entrevista.sexo;
        mi_entrevista.temporada = obj_entrevista.temporada;
        mi_entrevista.turno_laboral = obj_entrevista.turno_laboral;
        mi_entrevista.anotaciones_html = obj_entrevista.anotaciones_html;
        mi_entrevista.id_cita = obj_entrevista.id_cita;
        return await ssmHttpService.post(API.entrevista, mi_entrevista.toJson());
    }

    @Action
    public async modificarentrevista(obj_entrevista: entrevista) {
        await ssmHttpService.put(API.entrevista + '/' + obj_entrevista.id, obj_entrevista);
    }
    @Action({ commit: 'onGetdescargapdf' })
    public async descargarconsultaPDF(id: number) {
        return await ssmHttpService.get(API.entrevista + '/pdf_resultado_consulta/' + id, null, false, true);
    }


    @Mutation
    public onGuardarEntrevista(res: entrevista) {
        this.entrevista = new entrevista(res);
    }

    @Mutation
    public onGetentrevistas(res: entrevista[]) {
        this.entrevistas = res ? res.map((x) => new entrevista(x)) : [];
    }

    @Mutation
    public onGetentrevista(res: entrevista) {
        this.entrevista = new entrevista(res);
    }

    @Mutation
    public async onGetdescargapdf(response: any) {
        UtilsFiles.CreateURLFromBase64AndOpen(response.data, 'application/pdf');
    }

}
export default getModule(entrevistaModule);