



















































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { entrevista } from "@/shared/dtos/entrevista";
import entrevistaModule from "@/store/modules/entrevista-module";
import { DxDateBox } from "devextreme-vue";
import cabezera_preguntaModule from "@/store/modules/cabezera_pregunta-module";
import hist_preguntas_pacienteModule from "@/store/modules/hist_preguntas_paciente-module";
import { hist_preguntas_paciente } from "@/shared/dtos/hist_preguntas_paciente";
import { ssmMessageService } from "@/shared/services/message-service";
import pacienteModule from "@/store/modules/paciente-module";
import { paciente } from "@/shared/dtos/paciente-dto";
import { UtilsPaciente } from "@/utils/utils-paciente";
import { UtilsString } from "@/utils/utils-string";
import { RouterNames } from "@/router/routernames";
import { UtilsPublicResources } from "@/utils/utils-public-resources";
import { UtilsEncript } from "@/utils/utils-encript";
import EntrevistaPantalla from "./EntrevistaPatalla";
@Component({
  components: {
    ComSlider: () => import("@/views/entrevista/CompSlider.vue"),
    CompHeaderLabel: () => import("@/views/entrevista/CompHeaderLabel.vue"),
    DxDateBox,
    CompEstacion: () => import("@/views/entrevista/CompEstacion.vue"),
    CompEstiloAlimentacion: () =>
      import("@/views/entrevista/CompEstiloAlimentacion.vue"),
    CompTipoDeHorario: () => import("@/views/entrevista/CompTipoDeHorario.vue"),
    CompActividadFisica: () =>
      import("@/views/entrevista/CompActividadFisica.vue"),
    CompPracticaDeporte: () =>
      import("@/views/entrevista/CompPracticaDeporte.vue"),
    CompObjetivo: () => import("@/views/entrevista/CompObjetivo.vue"),
    CompKcalDieta: () => import("@/views/entrevista/CompKcalDieta.vue"),
    CompSexo: () => import("@/views/entrevista/CompSexo.vue"),
    CompFin: () => import("@/views/entrevista/CompFin.vue"),
    HTMLEditor: () => import("@/components/html-editor/html-editor.vue"),
    CompQuestion: () => import("@/views/entrevista/CompQuestion.vue"),
    CompSelectFinalEntrevista: () =>
      import("@/views/entrevista/CompSelectFinalEntrevista.vue")
  }
})
export default class EntrevistaAsistente extends Vue {
  public length: number = 19;
  public onboarding: number = 0;
  public id_paciente: number = 20;
  public id_cita: number = 0;
  public id_entrevista: number = 0;
  public nombre_paciente: string = "";
  public ruta_imagen: string = "";
  public editar: boolean = false;
  public buttons_validator: boolean[] = [];
  public text_empezar: string = "Empezar consulta";
  public dialogFin: boolean = false;
  //pasos a salar si ya tienen datos
  public pasos_saltar: EntrevistaPantalla[] = [];

  public async created() {
    if (UtilsString.IsValidParamUrl(this.$route.query.id_paciente)) {
      this.id_paciente = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id_paciente)
      );
    }
    if (UtilsString.IsValidParamUrl(this.$route.query.id_cita)) {
      this.id_cita = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id_cita)
      );
    }
    if (UtilsString.IsValidParamUrl(this.$route.query.id_entrevista)) {
      this.id_entrevista = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id_entrevista)
      );
      entrevistaModule.getentrevista({
        id_paciente: this.id_paciente,
        id_entrevista: this.id_entrevista
      });
      this.text_empezar = "Modificar consulta ";
      this.editar = true;
      //si se abre en modo edición , las preguntas y respuestas no se enseñan con lo que es 1 menos
      this.length = this.length - 1;
    }

    let obj_paciente = new paciente();
    obj_paciente.numero = this.id_paciente;
    await pacienteModule.getpaciente(obj_paciente);
    this.nombre_paciente = UtilsPaciente.getNameApellidos(
      pacienteModule.paciente
    );
    this.ruta_imagen = UtilsPaciente.getrutaImagen(pacienteModule.paciente);

    for (let i = 0; i < this.length; i++) {
      this.buttons_validator.push(this.editar);
    }
    if (!this.editar) {
      //intro
      this.buttons_validator[0] = true;
      //fechas
      this.buttons_validator[1] = true;
      this.buttons_validator[2] = true;
      //fromualrios opcionales
      this.buttons_validator[16] = true;
      this.buttons_validator[17] = true;
      //paso final
      this.buttons_validator[this.buttons_validator.length - 1] = true;
      await entrevistaModule.getnuevaentrevista(this.id_paciente);
    }

    if (!this.DataEntrevista.primera_consulta) {
      //Ahora creamos los passos a saltar si ya hay datos
      //la fecha de entrevista si ya esta rellenada
      let pnt_entrevista: EntrevistaPantalla = new EntrevistaPantalla();
      pnt_entrevista.id = 1;
      pnt_entrevista.dato = this.DataEntrevista.fecha_nacimiento;
      this.pasos_saltar.push(pnt_entrevista);

      //la fecha de nacimiento si ya esta rellenada
      let pnt_fecha: EntrevistaPantalla = new EntrevistaPantalla();
      pnt_fecha.id = 2;
      pnt_fecha.dato = this.DataEntrevista.fecha_nacimiento;
      this.pasos_saltar.push(pnt_fecha);
      //El sexo
      let pnt_sexo: EntrevistaPantalla = new EntrevistaPantalla();
      pnt_sexo.id = 3;
      pnt_sexo.dato = this.DataEntrevista.sexo;
      this.pasos_saltar.push(pnt_sexo);
      //la altura
      let pnt_altura: EntrevistaPantalla = new EntrevistaPantalla();
      pnt_altura.id = 4;
      pnt_altura.dato = this.DataEntrevista.altura;
      this.pasos_saltar.push(pnt_altura);

      //la muñeca
      let pnt_muñeca: EntrevistaPantalla = new EntrevistaPantalla();
      pnt_muñeca.id = 6;
      pnt_muñeca.index_validador = 7;
      pnt_muñeca.dato = this.DataEntrevista.muñeca;
      this.pasos_saltar.push(pnt_muñeca);
    }
  }
  public get botones() {
    return this.buttons_validator;
  }
  public get DataEntrevista() {
    return entrevistaModule.entrevista;
  }
  public validar(obj: any) {
    this.buttons_validator[obj.index] = obj.valido;
  }
  public Navigateto(index: number) {
    for (let i = 0; i < index; i++) {
      const element = this.buttons_validator[i];
      if (!element) {
        ssmMessageService.toastinfo(
          "complete este paso antes de avanzar a los siguientes"
        );
        this.onboarding = i;
        return;
      }
    }
    this.onboarding = index;
    return;
  }

  public next() {
    //comprobamos si el siguiente esta en los datos que hay que saltarse.

    if (this.pasos_saltar.filter(x => x.id === this.onboarding).length > 0) {
      //si esta en los pasos a saltarse comprobamos que tenga datos
      let paso = this.pasos_saltar.filter(x => x.id === this.onboarding);
      if (paso[0].dato !== undefined) {
        if (paso[0].index_validador !== undefined) {
          if (paso[0].index_validador > 0) {
            this.buttons_validator[paso[0].index_validador] = true;
          }
        }
        this.buttons_validator[this.onboarding] = true;
        this.onboarding =
          this.onboarding + 1 === this.length ? 0 : this.onboarding + 1;
        this.next();
      }
    }
    if (!this.buttons_validator[this.onboarding]) {
      ssmMessageService.toastinfo(
        "complete este paso antes de avanzar al siguiente"
      );
      return;
    }

    this.onboarding =
      this.onboarding + 1 === this.length ? 0 : this.onboarding + 1;
  }
  public prev() {
    this.onboarding =
      this.onboarding - 1 < 0 ? this.length - 1 : this.onboarding - 1;
  }

  public async fin() {
    //hago la validacion de todo
    let entrevista_guardada: boolean = false;
    let preguntas_guardadas: boolean = false;
    for (let i = 0; i < this.length; i++) {
      const element = this.buttons_validator[i];
      if (!element) {
        ssmMessageService.toastinfo(
          "complete este paso antes de finalizar la consulta"
        );
        this.onboarding = i;
        return;
      }
    }

    // Si no es editar estonces estoy creando
    if (!this.editar) {
      entrevistaModule.entrevista.id_cita = UtilsString.ValueOf(this.id_cita);
      await entrevistaModule
        .guardarentrevista(entrevistaModule.entrevista)
        .then((resultado: entrevista) => {
          this.id_entrevista = resultado.id;
          entrevista_guardada = true;
        })
        .catch(() => ssmMessageService.toastinfo("No se ha podido guardar"));
    } else {
      await entrevistaModule
        .modificarentrevista(entrevistaModule.entrevista)
        .then((resultado: any) => (entrevista_guardada = true))
        .catch(() => ssmMessageService.toastinfo("No se ha podido guardar"));
    }

    let Listhist: hist_preguntas_paciente[] = [];
    //si la colnsulta se abre en modo edición no guardamos nada
    if (!this.editar) {
      for (
        let i = 0;
        i < cabezera_preguntaModule.cabezera_preguntas.length;
        i++
      ) {
        const element = cabezera_preguntaModule.cabezera_preguntas[i];

        if (element.itemseleccionado !== undefined) {
          if (element.itemseleccionado > 0) {
            let hist: hist_preguntas_paciente = new hist_preguntas_paciente();
            hist.id_pregunta = element.id;
            hist.id_respuesta = element.itemseleccionado;
            hist.fecha = entrevistaModule.entrevista.fecha_entrevista;
            hist.id_paciente = this.id_paciente;
            hist.id = 0;
            Listhist.push(hist);
          }
        }
      }
    }
    //si no se selecciona niguna o la consulta se abre en modo edición
    if (Listhist.length > 0) {
      await hist_preguntas_pacienteModule
        .guardarhist_preguntas_paciente(Listhist)
        .then(() => (preguntas_guardadas = true))
        .catch(() =>
          ssmMessageService.toastinfo("No se han podido guardar las preguntas")
        );
    } else {
      preguntas_guardadas = true;
    }

    if (preguntas_guardadas && entrevista_guardada) {
      this.dialogFin = true;
    }
  }

  public imprimir_resultado() {
    entrevistaModule.descargarconsultaPDF(entrevistaModule.entrevista.id);
  }

  public getimagen(imagen: string) {
    return UtilsPublicResources.getImageFromImages(imagen);
  }
}
